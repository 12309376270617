import { LoadIndicator } from "devextreme-react";
import { useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
import { CustomSelectPickerProps } from "../type";

const FetchSelectPicker = ({
  label,
  error,
  field,
  touched,
  fetch,
  show,
  ...rest
}: CustomSelectPickerProps) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      if (fetch) {
        try {
          setLoading(true); // Veri çekme işlemi başladığında loading'i true yapıyoruz
          const result = await fetch(); // fetchCities'i çağırıyoruz
          console.log(result.Result);
          setData(result.Result); // Gelen veriyi state'e kaydediyoruz
        } catch (error) {
          console.log(error, "ERROR");
        } finally {
          setLoading(false); // İstek tamamlandığında loading'i false yapıyoruz
        }
      }
    };

    show && getData(); // eslint-disable-next-line
  }, [show]);
  return (
    <div className="textOnInput my-0">
      <label
        className={error && touched ? "errorLabel customlabel" : "customlabel"}
        htmlFor={field}
        style={{ background: "white" }}
      >
        {label ? label : ""}
      </label>
      <SelectPicker
        className={
          error && touched
            ? "errorInput defaultInput form-control selectpickerInput form2-control"
            : "defaultInput form-control selectpickerInput form2-control"
        }
        renderMenu={menu => {
          if (loading) {
            return (
              <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
                <LoadIndicator />
              </p>
            );
          }
          return menu;
        }}
        {...rest}
        data={data}
      />
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default FetchSelectPicker;
