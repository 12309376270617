import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import Breadcrumbs from "src/components/main-list-page/breadcrumb/Breadcrumbs";
import { createCoupon, getCouponGroups } from "src/store/coupons/saga";
import { CouponGroupDto } from "src/store/coupons/type";
import {
  getCouponValidityTypes,
  getDiscountTypeLookupList,
} from "src/store/lookup/saga";
import { DiscountTypeDto, EnumType } from "src/store/lookup/type";
import { getPackageList } from "src/store/packages/saga";
import { PackageDto } from "src/store/packages/type";
import * as Yup from "yup";
import CreateCouponIncludedPackage from "./comp/CouponIncludedPackages";
import { DataGrid } from "devextreme-react";
import CreateCouponIncludedPrivilege from "./comp/CouponIncludedPrivileges";
import { PrivilegeDto } from "src/store/privilege/type";
import { getAllPrivilegeList } from "src/store/privilege/saga";
import { useHistory } from "react-router";

const CreateCouponPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [packages, setPackages] = useState<PackageDto[]>([]);
  const [groups, setGroups] = useState<CouponGroupDto[]>([]);
  const [discountTypes, setDiscountTypes] = useState<DiscountTypeDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [types, setTypes] = useState<EnumType[]>([]);
  const packageGridRef = useRef<DataGrid>(null);
  const privilegeGridRef = useRef<DataGrid>(null);
  useEffect(() => {
    dispatch(
      getAllPrivilegeList({
        payload: {
          onSuccess: (msg, py) => setPrivileges(py),
          onError: () => {},
        },
      })
    );
    dispatch(
      getDiscountTypeLookupList({
        payload: {
          onSuccess: (msg, payload) => setDiscountTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getCouponGroups({
        payload: {
          onSuccess: (msg, payload) => setGroups(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getCouponValidityTypes({
        payload: {
          onSuccess: (msg, payload) => setTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getPackageList({
        payload: {
          onSuccess: (msg, payload) =>
            setPackages(payload.filter(x => !x.System)),
          onError: () => {},
        },
      })
    );
  }, [dispatch]);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs />
          <div style={{ marginInline: "0.5rem" }}>
            <CustomFormik
              loader
              initialValues={{
                Name: "",
                Description: "",
                Code: "",
                MinAmount: "",
                MaxAmount: "",
                StartDate: "",
                EndDate: "",
                Package: false,
                PackageDiscountRate: "",
                Privilege: false,
                PrivilegeDiscountRate: "",
                Limited: false,
                LimitCount: "",
                AutomaticPayment: false,
                Active: false,
                IncludedGift: false,
                CouponValidityDuration: "",
                CouponGroupId: "",
                CouponIncludedPackages: [],
                CouponIncludedPrivileges: [],
              }}
              onSubmit={values => {
                dispatch(
                  createCoupon({
                    payload: {
                      onSuccess: (message, payload) => {
                        toast.success(message);
                        history.push("/coupons/edit/" + payload.Id);
                        // onHide();
                        // onSuccessClick(message);
                      },
                      onError: message => {
                        toast.error(message, {
                          theme: "colored",
                        });
                      },
                    },
                    body: {
                      ...values,
                    },
                  })
                );
              }}
              validationSchema={Yup.object().shape({
                Name: Yup.string().required(t("Required")),
                Description: Yup.string().required(t("Required")),
                Code: Yup.string().required(t("Required")),
              })}
              inputs={[
                {
                  field: "Name",
                  label: t("Name"),
                  col: 4,
                },
                {
                  field: "Code",
                  label: t("Code"),
                  col: 4,
                },
                {
                  field: "CouponGroupId",
                  inputType: InputType.multiselect,
                  label: t("Grup"),
                  lookup: {
                    data: groups,
                    labelKey: "Name",
                    valueKey: "Id",
                  },
                  col: 4,
                },
                {
                  field: "Description",
                  label: t("Description"),
                  inputType: InputType.multilinetext,
                },
                {
                  field: "StartDate",
                  label: t("Start Date"),
                  inputType: InputType.datetime,
                  col: 6,
                },
                {
                  field: "EndDate",
                  label: t("End Date"),
                  inputType: InputType.datetime,
                  col: 6,
                },
                {
                  field: "MinAmount",
                  label: t("Min Amount"),
                  inputType: InputType.number,
                  col: 6,
                },
                {
                  field: "MaxAmount",
                  label: t("Max Amount"),
                  col: 6,
                  inputType: InputType.number,
                },
                {
                  field: "DiscountTypeId",
                  inputType: InputType.multiselect,
                  label: t("Discount Type"),
                  lookup: {
                    data: discountTypes,
                    labelKey: "Name",
                    valueKey: "EnumId",
                    placeholder: t("Choose Discount Type"),
                  },
                },
                {
                  field: "CouponValidityTypeId",
                  inputType: InputType.multiselect,
                  label: t("Aylık/Yıllık Seçimi"),
                  lookup: {
                    data: types,
                    labelKey: "Name",
                    valueKey: "EnumId",
                    placeholder: t("Choose Discount Type"),
                  },
                },
                {
                  field: "CouponValidityDuration",
                  label: t("Kupon Kaç Ay Geçerli?"),
                  inputType: InputType.number,
                },
                {
                  field: "Package",
                  label: t("Package"),
                  inputType: InputType.checkbox,
                  col: 2,
                },
                {
                  field: "PackageDiscountRate",
                  label: t("Package Discount Rate"),
                  inputType: InputType.number,
                  col: 10,
                },
                {
                  field: "Privilege",
                  label: t("Privilege"),
                  col: 2,
                  inputType: InputType.checkbox,
                },
                {
                  field: "PrivilegeDiscountRate",
                  label: t("Privilege Discount Rate"),
                  col: 10,
                  inputType: InputType.number,
                },
                {
                  field: "Limited",
                  label: t("Limited"),
                  col: 2,
                  inputType: InputType.checkbox,
                },

                {
                  field: "LimitCount",
                  label: t("Limit Count"),
                  col: 10,
                  inputType: InputType.number,
                },
                {
                  field: "IncludedGift",
                  label: t("Hediyeleri Dahil Et"),
                  inputType: InputType.checkbox,
                },
                {
                  field: "AutomaticPayment",
                  label: t("Otomatik Paket Yenilemesini Aktif Et"),
                  inputType: InputType.checkbox,
                },
                {
                  field: "Active",
                  label: t("Active"),
                  inputType: InputType.checkbox,
                },
                {
                  field: "CouponIncludedPackages",
                  label: t(""),

                  inputType: InputType.component,
                  component(data, setFieldValue) {
                    return (
                      <CreateCouponIncludedPackage
                        ref={packageGridRef}
                        data={data.CouponIncludedPackages}
                        packages={packages}
                        discountTypes={discountTypes}
                        onInserted={(e: any) => {
                          setFieldValue("CouponIncludedPackages", [
                            ...data.CouponIncludedPackages.filter(
                              (x: any) => x.Id !== e.data.Id
                            ),
                            e.data,
                          ]);
                        }}
                        onRemoved={(e: any) => {
                          setFieldValue("CouponIncludedPackages", [
                            ...data.CouponIncludedPackages.filter(
                              (x: any) => x.Id !== e.data.Id
                            ),
                          ]);
                        }}
                      />
                    );
                  },
                },
                {
                  field: "CouponIncludedPrivileges",
                  label: t(""),
                  inputType: InputType.component,
                  component(data, setFieldValue) {
                    return (
                      <CreateCouponIncludedPrivilege
                        ref={privilegeGridRef}
                        data={data.CouponIncludedPrivileges}
                        privileges={privileges}
                        onInserted={(e: any) => {
                          setFieldValue("CouponIncludedPrivileges", [
                            ...data.CouponIncludedPrivileges.filter(
                              (x: any) => x.Id !== e.Id
                            ),
                            e,
                          ]);
                        }}
                        onRemoved={(e: any) => {
                          setFieldValue("CouponIncludedPrivileges", [
                            ...data.CouponIncludedPrivileges.filter(
                              (x: any) => x.Id !== e.Id
                            ),
                          ]);
                        }}
                      />
                    );
                  },
                },
              ]}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CreateCouponPage;
