import { Props } from "../type";

const CustomTextarea = ({
  label,
  error,
  type = "text",
  field,
  style,
  value,
  touched,
  onChange,
  onBlur,
  disabled,
}: Props) => {
  return (
    <div className="textOnInput my-0">
      <label
        className={error && touched ? "errorLabel customlabel" : "customlabel"}
        htmlFor={field}
      >
        {label ? label : ""}
      </label>
      <textarea
        className={
          error && touched
            ? "errorInput defaultInput form-control form2-control"
            : "defaultInput form-control form2-control"
        }
        disabled={disabled}
        style={{ ...style }}
        value={value}
        id={field}
        onChange={(e: any) => onChange && onChange(e.target.value)}
        onBlur={(e: any) => onBlur && onBlur(e)}
      />
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomTextarea;
