import { LoadPanel } from "devextreme-react";
import { useSelector } from "react-redux";
import { isLoading } from "src/store/loader";
import { LoadFormProps } from "..";

interface EditGridButtonProps {
  title?: string;
  rowData: any;
  loadForm: (props: LoadFormProps) => any;
}
const EditGridButton = ({ title, rowData, ...rest }: EditGridButtonProps) => {
  const loader = useSelector(isLoading);

  return (
    <span>
      <span className="dx-link dx-icon-edit dx-link-icon" onClick={() => {}} />
      {loader ? <LoadPanel visible /> : <></>}
    </span>
  );
};
export default EditGridButton;
