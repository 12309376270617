import { MainListPage } from "src/components/main-list-page";
import { deleteSupplierIncludedBrands } from "src/store/supplier/saga";
import { SupplierDto } from "src/store/supplier/type";
import CreateSupplierIncludedBrandModal from "./create";

const SupplierIncludedBrandPage = ({ supplier }: { supplier: SupplierDto }) => {
  const columns = [
    {
      caption: "Marka",
      dataField: "VehicleBrandName",
    },
  ];
  return (
    <MainListPage
      url="/api/supplierincludedbrands/devext"
      columns={columns}
      title={""}
      filter={["SupplierId", "=", supplier.Id]}
      hideBreadcrumb={true}
      deleteButton={{
        deleteAction: deleteSupplierIncludedBrands,
      }}
      create={{
        loadForm(props) {
          return (
            <CreateSupplierIncludedBrandModal
              onHide={props.onHide}
              open={props.open}
              onSuccessClick={() => {}}
              supplierId={supplier.Id}
            />
          );
        },
        type: "modal",
      }}
    />
  );
};
export default SupplierIncludedBrandPage;
