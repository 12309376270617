import { useCallback, forwardRef, useEffect, ReactNode } from "react";
//@ts-ignore
import { jsPDF as JsPdf } from "jspdf";
//@ts-ignore
import { saveAs } from "file-saver-es";
import { Workbook } from "exceljs";
import DataGrid, {
  Sorting,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Export,
  Column,
  Toolbar,
  Item,
  LoadPanel,
  DataGridTypes,
  IDataGridOptions,
  Button as ColumnButton,
  IButtonProps as IColumnButtonProps,
  IItemProps,
  RowDragging,
} from "devextreme-react/data-grid";
import "./grid.scss";
import Button from "devextreme-react/button";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid as exportDataGridToXLSX } from "devextreme/excel_exporter";
import DataSource from "devextreme/data/data_source";
import EditGridButton from "./comp/edit";
import DeleteGridButton from "./comp/delete";
import notify from "devextreme/ui/notify";
import { useSelector } from "react-redux";
import { getApiParams } from "src/store/auth";
import { baseUrl } from "src/store/base/types/url";
import CreateGridButton from "./comp/create";
import { useHistory } from "react-router";
import Breadcrumbs from "./breadcrumb/Breadcrumbs";

function isNotEmpty(value: string | undefined | null) {
  return value !== undefined && value !== null && value !== "";
}
const onExporting = (e: DataGridTypes.ExportingEvent) => {
  if (e.format === "pdf") {
    const doc = new JsPdf();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
    }).then(() => {
      doc.save("Contacts.pdf");
    });
  } else {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Contacts");

    exportDataGridToXLSX({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Contacts.xlsx"
        );
      });
    });
    e.cancel = true;
  }
};
const exportFormats = ["xlsx", "pdf"];

export interface LoadFormProps {
  open: boolean;
  onHide: () => void;
  id?: string;
  data?: any;
  onSuccessClick: (message: string) => void;
}
interface GridButtonProps {
  title?: string;
  condition?: () => any;
  type?: "modal" | "url" | "custom";
  url?: string;
  onClick?: any;
  getAction?: any;
  loadForm?: (props: LoadFormProps) => any;
}
interface DetailButtonProps extends GridButtonProps {
  editPermission?: string;
  navigateUrl?: string;
  toolbarActions?: (data?: any, refetch?: any) => any;
  renderTitle?: (formInstance?: any, formData?: any) => string;
  onDetailClick?: (id: string) => void;
  component?: (instance?: any, formData?: any) => ReactNode;
}
interface MainListProps extends IDataGridOptions {
  url: string;
  title: string;
  columns: any[];
  columnButtons?: IColumnButtonProps[];
  gridItems?: IItemProps[];
  create?: GridButtonProps;
  edit?: GridButtonProps;
  detail?: DetailButtonProps;
  deleteButton?: {
    title?: string;
    message?: string;
    deleteAction: any;
    condition?: any;
  };
  hideBreadcrumb?: any;
  orderMutation?: any;
  showOrder?: boolean;
  isLoading?: boolean;
  filter?: any;
  detailKey?: string;
  detailUrl?: string;
  children?: any;
  isDetail?: boolean;
  dataSourceProps?: any;
  chooserProps?: any;
}

export const MainListPage = forwardRef<DataGrid, MainListProps>(
  (
    {
      isDetail,
      detailUrl,
      url,
      title,
      gridItems,
      columns,
      columnButtons,
      create,
      edit,
      filter,
      detail,
      deleteButton,
      showOrder = false,
      orderMutation,
      isLoading,
      hideBreadcrumb = false,
      detailKey,
      children,
      dataSourceProps,
      chooserProps,
      ...rest
    },
    ref
  ) => {
    const history = useHistory();
    const { token } = useSelector(getApiParams);
    const store = new DataSource({
      key: "Id",
      filter: filter ?? "",
      load: async (loadOptions: any) => {
        const paramNames = [
          "skip",
          "take",
          "requireTotalCount",
          "requireGroupCount",
          "sort",
          "filter",
          "totalSummary",
          "group",
          "groupSummary",
        ];
        let queryString = paramNames
          //@ts-ignore
          .filter(paramName => isNotEmpty(loadOptions[paramName]))
          .map(
            paramName =>
              //@ts-ignore
              `${paramName}=${JSON.stringify(loadOptions[paramName])}`
          )
          .join("&");

        const response = await fetch(`${baseUrl}${url}?${queryString}`, {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        });

        if (response.status === 200) {
          const result = await response.json();
          return {
            data: result.data,
            totalCount: result.totalCount,
            summary: result.summary,
            groupCount: result.groupCount,
          };
        } else {
          notify("Bir Hata Oluştu: " + response.status, "error", 5000);
          return {
            data: [],
            totalCount: 0,
            summary: 0,
            groupCount: 0,
          };
        }
      },

      ...dataSourceProps,
    });

    const allowedPageSizes = [10, 15];

    const refresh = useCallback(() => {
      //@ts-ignore
      ref?.current?.instance.refresh(); //eslint-disable-next-line
    }, []);

    //ORDER
    const [changeOrder, { error, isSuccess, isError }]: any = orderMutation
      ? orderMutation()
      : [() => {}, {}];
    const onReorder = useCallback((e: any) => {
      const visibleRows = e.component.getVisibleRows();
      //@ts-ignore
      const data = ref?.current?.instance.getDataSource()._items;
      const newTasks = [...data];
      const toIndex = newTasks.findIndex(
        item => item.Id === visibleRows[e.toIndex].data.Id
      );
      const fromIndex = newTasks.findIndex(item => item.Id === e.itemData.Id);
      newTasks.splice(fromIndex, 1);
      newTasks.splice(toIndex, 0, e.itemData);

      changeOrder(
        newTasks.map((x: any, i) => {
          return {
            Id: x.Id,
            RecordOrder: i + 1,
          };
        })
      );
      // eslint-disable-next-line
    }, []);
    useEffect(() => {
      isSuccess && responseShow("success");
      isError && responseShow("error"); // eslint-disable-next-line
    }, [isSuccess, isError]);
    const responseShow = (type: "error" | "success") => {
      let message = "Başarılı";
      if (type === "error") {
        console.log(error);
        message =
          "HATA: " + (error && error.data?.Message)
            ? error.data?.Message
            : JSON.stringify(error);
      }
      notify(message, type);
    };

    return (
      <div className={hideBreadcrumb ? "" : "page-content"}>
        {!hideBreadcrumb && (
          <div className="view-breadcrumb">
            <Breadcrumbs />
          </div>
        )}
        {!isLoading ? (
          <>
            <div className="view-wrapper  list-page">
              <DataGrid
                className={"maingGrid  wide-card"}
                noDataText=""
                focusedRowEnabled
                height="100%"
                key={"Id"}
                dataSource={store}
                showBorders={true}
                onExporting={onExporting}
                allowColumnReordering
                allowColumnResizing
                showColumnLines={false}
                showRowLines={true}
                remoteOperations
                rowAlternationEnabled
                onRowDblClick={e => {
                  if (detailUrl) {
                    history.push(detailUrl + e.data.Id);
                  }
                }}
                paging={{ enabled: true, pageSize: 10 }}
                pager={{
                  visible: true,
                  showInfo: true,
                  allowedPageSizes: allowedPageSizes,
                  showPageSizeSelector: true,
                }}
                headerFilter={{ visible: true }}
                filterRow={{ visible: true }}
                ref={ref}
                grouping={{
                  autoExpandAll: false,
                }}
                groupPanel={{ visible: true }}
                // columnAutoWidth
                // columnHidingEnabled
                {...rest}
              >
                {children}
                {showOrder && orderMutation && (
                  <RowDragging
                    allowReordering={true}
                    onReorder={onReorder}
                    showDragIcons={true}
                  />
                )}
                <LoadPanel showPane={true} />
                <SearchPanel visible placeholder="Arama" />
                <ColumnChooser enabled {...chooserProps} />
                <Export
                  enabled
                  allowExportSelectedData
                  formats={exportFormats}
                />
                <HeaderFilter visible />
                <Sorting mode="multiple" />

                <Toolbar>
                  <Item name="groupPanel" location="before" />

                  <Item>
                    <Button
                      icon="close"
                      type="normal"
                      hint="Filtre Temizle"
                      stylingMode="text"
                      //@ts-ignore
                      onClick={() => ref?.current?._instance?.state(null)}
                    />
                  </Item>
                  <Item
                    location="after"
                    locateInMenu="auto"
                    showText="inMenu"
                    widget="dxButton"
                  >
                    <Button
                      icon="refresh"
                      text="Refresh"
                      stylingMode="text"
                      onClick={refresh}
                    />
                  </Item>

                  <Item name="exportButton" />
                  <Item name="columnChooserButton" />
                  <Item name="searchPanel" locateInMenu="auto" />
                  {showOrder && orderMutation && (
                    <Item>
                      <Button
                        icon="orderedlist"
                        text="Sıralamayı Düzelt"
                        stylingMode="contained"
                        onClick={() => {
                          const data =
                            //@ts-ignore
                            ref?.current?.instance.getDataSource()._items;
                          let newTasks = [...data];
                          newTasks = newTasks.map((x: any, i) => {
                            return {
                              Id: x.Id,
                              RecordOrder: i + 1,
                            };
                          });
                          changeOrder(newTasks);
                        }}
                      />
                    </Item>
                  )}
                  {gridItems?.map((x, i) => (
                    <Item key={`customGridItem${i}`} {...x} />
                  ))}
                  <Item location="after" locateInMenu="auto">
                    {create && create.type === "modal" && create.loadForm && (
                      <CreateGridButton
                        visible={create.condition ? create.condition() : true}
                        title={create.title}
                        afterSubmit={refresh}
                        loadForm={create.loadForm}
                      />
                    )}
                    {create && create.type === "custom" && (
                      <Button
                        visible={create.condition ? create.condition() : true}
                        icon="plus"
                        type="normal"
                        text="Yeni Ekle"
                        stylingMode="contained"
                        onClick={() => create.onClick}
                      />
                    )}
                    {create && create.type === "url" && create.url && (
                      <Button
                        visible={create.condition ? create.condition() : true}
                        icon="plus"
                        type="normal"
                        text="Yeni Ekle"
                        stylingMode="contained"
                        onClick={() => create.url && history.push(create.url)}
                      />
                    )}
                  </Item>
                </Toolbar>
                {columns.map((x, i) => (
                  <Column key={i} {...x} />
                ))}
                <Column type="buttons" width={"auto"}>
                  {columnButtons?.map((x, i) => {
                    return (
                      <ColumnButton key={`customColumnButton${i}`} {...x} />
                    );
                  })}

                  {detailUrl && (
                    <ColumnButton
                      component={e => {
                        const rowData = e.data.row.data;
                        return (
                          <span
                            className="dx-link dx-icon-info dx-link-icon"
                            onClick={() =>
                              detailUrl && history.push(detailUrl + rowData.Id)
                            }
                          />
                        );
                      }}
                    />
                  )}
                  {edit &&
                    edit.getAction &&
                    edit.type === "modal" &&
                    edit.loadForm && (
                      <ColumnButton
                        visible={edit.condition ? edit.condition() : true}
                        component={e => {
                          const rowData = e.data.row.data;
                          return (
                            <EditGridButton
                              rowData={rowData}
                              //@ts-ignore
                              loadForm={edit.loadForm}
                            />
                          );
                        }}
                      />
                    )}
                  {edit && edit.type === "url" && edit.url && (
                    <ColumnButton
                      visible={edit.condition ? edit.condition() : true}
                      component={e => {
                        const rowData = e.data.row.data;
                        return (
                          <span
                            className="dx-link dx-icon-edit dx-link-icon"
                            onClick={() => history.push(edit.url + rowData.Id)}
                          />
                        );
                      }}
                    />
                  )}
                  {deleteButton && (
                    <ColumnButton
                      visible={
                        deleteButton.condition ? deleteButton.condition() : true
                      }
                      component={e => {
                        const rowData = e.data.row.data;
                        return (
                          <DeleteGridButton
                            afterSubmit={refresh}
                            rowData={rowData}
                            {...deleteButton}
                          />
                        );
                      }}
                    />
                  )}
                </Column>
              </DataGrid>
              <div id={"DetailDrawerElement" + detailKey} />
            </div>
          </>
        ) : (
          <>
            <LoadPanel enabled />
          </>
        )}
      </div>
    );
  }
);
