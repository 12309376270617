import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { fetchVehicleBrandLookupList } from "src/api/api";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import { createPrivilegeIncludedBrands } from "src/store/privilege/saga";
import * as Yup from "yup";

const CreatePrivilegeIncludedBrandModal = ({
  onHide,
  privilegeId,
  open,
  onSuccessClick,
}: {
  privilegeId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchVehicleBrandLookupList(); // fetchCities'i çağırıyoruz
        console.log(result.Result);
        setBrands(result.Result); // Gelen veriyi state'e kaydediyoruz
      } catch (error) {
        console.log(error, "ERROR");
      } finally {
      }
    };
    open && getData(); // eslint-disable-next-line
  }, [open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Marka Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: privilegeId,
          VehicleBrandIds: [],
        }}
        onSubmit={values => {
          dispatch(
            createPrivilegeIncludedBrands({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick("Markalar başarıyla eklenmiştir");
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PrivilegeId: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "VehicleBrandIds",
            inputType: InputType.checkpicker,
            label: t("Markalar"),
            lookup: {
              data: brands,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: "Marka Seçiniz",
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeIncludedBrandModal;
