import React from "react";
import { DataBankDto } from "src/store/databank/type";
import CustomModal from "src/components/Modal/drawer";
import Input from "src/components/Form/Input";
import { Col, Row } from "reactstrap";
import CustomTextarea from "src/components/Form/Input/Textarea";

const DatabankDetail = ({
  data,
  show,
  setShow,
}: {
  data: DataBankDto;
  show: boolean;
  setShow: (show: boolean) => void;
}) => {
  return (
    <React.Fragment>
      <CustomModal
        open={show}
        onHide={() => setShow(false)}
        title={"Bilgi Bankası"}
      >
        <Row>
          <Col md={12}>
            <Input
              disabled
              field="Kategori"
              label="Kategori"
              value={data.DataBankCategoryName}
            />
          </Col>
          <Col md={12}>
            <CustomTextarea
              disabled
              field="Question"
              label="Soru"
              value={data.Question}
            />
          </Col>
          <Col md={12}>
            <div className="textOnInput my-0">
              <label className={"customlabel"} htmlFor={"Answer"}>
                {"Cevap"}
              </label>
              <div dangerouslySetInnerHTML={{ __html: data.Answer }} />
            </div>
          </Col>
        </Row>
      </CustomModal>
    </React.Fragment>
  );
};
export default DatabankDetail;
