import { confirm } from "devextreme/ui/dialog";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
interface DeleteGridButtonProps {
  title?: string;
  message?: string;
  rowData: any;
  deleteAction: any;
  afterSubmit?: () => void;
}
const DeleteGridButton = ({
  title,
  message,
  rowData,
  deleteAction,
  afterSubmit,
}: DeleteGridButtonProps) => {
  const dispatch = useDispatch();
  return (
    <span>
      <span
        className="dx-link dx-icon-trash dx-link-icon"
        onClick={(e: any) => {
          let confirmResult = confirm(
            message ?? "Silme İşlemi",
            title ?? "Silmek istediğinize emin misiniz?"
          );
          confirmResult.then(async dialogResult => {
            if (dialogResult) {
              dispatch(
                deleteAction({
                  payload: {
                    onSuccess: (message: any) => {
                      toast.success(message);
                    },
                    onError: (message: any) => {
                      toast.error(message, {
                        theme: "colored",
                      });
                    },
                  },
                  id: rowData.Id,
                })
              );
            }
          });
        }}
      />
    </span>
  );
};
export default DeleteGridButton;
