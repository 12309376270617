import { useCallback, useMemo, useRef, useState } from "react";
import DropDownBox, { DropDownBoxTypes } from "devextreme-react/drop-down-box";
import DataGrid, {
  LoadPanel,
  SearchPanel,
  HeaderFilter,
  Sorting,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { baseUrl } from "src/store/base/types/url";
import { useSelector } from "react-redux";
import { getApiParams } from "src/store/auth";
import DataSource from "devextreme/data/data_source";
import { Button } from "devextreme-react";

function isNotEmpty(value: string | undefined | null) {
  return value !== undefined && value !== null && value !== "";
}

const ownerLabel = { "aria-label": "Owner" };

// Memoized Async Data Source
const makeAsyncDataSource = (token: string, url: string, key: string) =>
  new DataSource({
    key: key,
    load: async (loadOptions: any) => {
      const paramNames = [
        "skip",
        "take",
        "requireTotalCount",
        "requireGroupCount",
        "sort",
        "filter",
        "totalSummary",
        "group",
        "groupSummary",
      ];
      let queryString = paramNames
        //@ts-ignore
        .filter(paramName => isNotEmpty(loadOptions[paramName]))
        .map(
          paramName =>
            //@ts-ignore
            `${paramName}=${JSON.stringify(loadOptions[paramName])}`
        )
        .join("&");
      const response = await fetch(`${baseUrl}${url}?${queryString}`, {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      });
      if (response.status === 200) {
        const result = await response.json();
        return {
          data: result.data,
          totalCount: result.totalCount,
          summary: result.summary,
          groupCount: result.groupCount,
        };
      } else {
        return {
          data: [],
          totalCount: 0,
          summary: 0,
          groupCount: 0,
        };
      }
    },
  });

function GridBox({
  columns,
  gridLookupUrl,
  onChange,
  labelKey,
  valueKey = "Id",
  filter,
  label,
  value,
  error,
  touched,
  field,
}: {
  field: string;
  error?: any;
  touched?: any;
  value?: any;
  columns: any[];
  gridLookupUrl: string;
  label?: string;
  onChange: (value: any) => void;
  labelKey: string;
  valueKey: string;
  filter?: any;
}) {
  const gridRef = useRef<DataGrid>(null);
  const { token } = useSelector(getApiParams);
  // Memoize the data source
  const store = useMemo(
    () => makeAsyncDataSource(token, gridLookupUrl, valueKey), // eslint-disable-next-line
    [token]
  );
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [gridBoxValue, setGridBoxValue] = useState<any>();
  const onGridBoxOpened = useCallback(
    (e: DropDownBoxTypes.OptionChangedEvent) => {
      if (e.name === "opened") {
        setIsGridBoxOpened(e.value);
      }
    },
    []
  );
  // const gridBoxDisplayExpr = (item: { Name: any }) => {
  //   return item ? item.Name : "";
  // };
  return (
    <div>
      {label && (
        <label
          className={
            error && touched ? "errorLabel customlabel" : "customlabel"
          }
          htmlFor={field}
        >
          {label}
        </label>
      )}
      <DropDownBox
        stylingMode="outlined"
        key={field}
        opened={isGridBoxOpened}
        valueExpr={valueKey}
        value={value}
        deferRendering={false}
        inputAttr={ownerLabel}
        style={{ padding: 2 }}
        dataSource={gridBoxValue ? [gridBoxValue] : []}
        placeholder="Select a value..."
        displayExpr={labelKey}
        onOptionChanged={onGridBoxOpened}
        contentRender={() =>
          isGridBoxOpened ? (
            <DataGrid
              ref={gridRef}
              className={"maingGrid  wide-card"}
              noDataText=""
              focusedRowEnabled
              height="100%"
              key={valueKey}
              dataSource={store}
              showBorders={true}
              allowColumnReordering
              allowColumnResizing
              columns={columns}
              showColumnLines={false}
              showRowLines={true}
              remoteOperations={{
                filtering: true,
                sorting: true,
                paging: true,
                grouping: true,
              }}
              rowAlternationEnabled
              paging={{ enabled: true, pageSize: 10 }}
              pager={{
                visible: true,
                showInfo: true,
                allowedPageSizes: [10, 15],
                showPageSizeSelector: true,
              }}
              headerFilter={{ visible: true }}
              filterRow={{ visible: true }}
              onRowClick={e => {
                setGridBoxValue(e.data);
                setIsGridBoxOpened(false);
                onChange(e.data[valueKey]);
              }}
            >
              <Toolbar>
                <Item
                  location="after"
                  locateInMenu="auto"
                  showText="inMenu"
                  widget="dxButton"
                >
                  <Button
                    icon="refresh"
                    text="Refresh"
                    onClick={() => {
                      gridRef.current?.instance.refresh();
                    }}
                  />
                </Item>
              </Toolbar>
              <LoadPanel showPane={true} />
              <SearchPanel visible placeholder="Arama" />
              <HeaderFilter visible />
              <Sorting mode="multiple" />
            </DataGrid>
          ) : (
            <></>
          )
        }
      />
    </div>
  );
}

export default GridBox;
