import React, { useEffect, useRef } from "react";
import "devextreme/dist/css/dx.softblue.css";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import "./App.scss";
// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";
import "devexpress-reporting/dx-reportdesigner";
import "devexpress-reporting/dx-webdocumentviewer";
//redux
import { useDispatch, useSelector } from "react-redux";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

import trMessages from "devextreme/localization/messages/tr.json";
import { locale as devexLocale, loadMessages } from "devextreme/localization";
import "rsuite/dist/rsuite.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-drawer/lib/react-drawer.css";
import "flatpickr/dist/themes/material_blue.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/index";
import NonAuthLayout from "./components/NonAuthLayout";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import { ToastContainer, toast } from "react-toastify";
import { baseUrl } from "./store/base/types/url";
import { getApiParams } from "./store/auth";
import notificationRedux from "./store/socketNotification";
import ContractDesigner from "./pages/Report/Designer";
import ContractViewer from "./pages/Report/Viewer";
const App = () => {
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }));
  useEffect(() => {
    loadMessages(trMessages);
    devexLocale("tr");
  }, []);
  function getLayout() {
    let layoutCls: Object = VerticalLayout;
    switch (layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <ToastContainer theme="colored" position="top-center" autoClose={1000} />
      <Router>
        <ConnectionNotification />
        <Route path={"/report-designer"} component={ContractDesigner} />
        <Route path={"/report-viewer"} component={ContractViewer} />
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              isNotAdmin={route.isNotAdmin}
              permission={route.permission}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route: any, idx: number) => (
            <Authmiddleware
              roles={route.roles}
              isNotAdmin={route.isNotAdmin}
              path={route.path}
              permission={route.permission}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

export default App;

const ConnectionNotification = () => {
  const dispatch = useDispatch();
  const HUB_ENDPOINT = baseUrl + "/api/notification";
  const { lang, token } = useSelector(getApiParams);
  const connectionRef = useRef<HubConnection | null>(null);
  useEffect(() => {
    if (!connectionRef.current && token) {
      const connection = new HubConnectionBuilder()
        .withUrl(HUB_ENDPOINT, {
          headers: { "Accept-Language": `${lang}` },
          accessTokenFactory: () => token,
          //Hub connection CORS ERROR FIX
          withCredentials: false,
        })
        .configureLogging(LogLevel.Debug)
        .withAutomaticReconnect()
        .build();
      connection
        .start()
        .then(function () {
          console.log("connection started");
        })
        .catch((error: any) => {
          console.error(error.message);
        });
      console.log(`Trying to connect to ${HUB_ENDPOINT}`);
      connection.on(
        "notification",
        function (data: {
          type: any;
          message: any;
          url: any;
          title: any;
          id: string;
        }) {
          let click = false;
          toast.info(data.message, {
            theme: "colored",
            autoClose: 10000,
            position: "top-right",
            onClick: () => {
              dispatch(notificationRedux.actions.readNotification(data.id));
              return window.open(data.url, "_blank");
            },
          });
          !click && dispatch(notificationRedux.actions.getNotifications());
        }
      );
      connection.onclose(async () => {
        console.log(`Disconnected from ${HUB_ENDPOINT}`);
        connectionRef.current = null;
      });
      connectionRef.current = connection;
    } else console.log("CONNECTION ALREADY ESTABLIESHED"); // eslint-disable-next-line
  }, []);

  return <></>;
};
