import axios from "axios"; // Redux store'u doğrudan alıyoruz
import { baseUrl } from "src/store/base/types/url";
const persistStore = localStorage.getItem("persist:root");
const store = persistStore ? JSON.parse(persistStore) : "";
const auth = store.auth ? JSON.parse(store.auth) : "";
export const baseQuery = async ({
  url,
  params,
}: {
  url: string;
  params?: any;
}) => {
  try {
    const token = auth?.user?.AccessToken.Token;
    const response = await axios.get(`${baseUrl}${url}`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`, // Token'ı header'a ekliyoruz
      },
    });
    return response.data;
  } catch (error: any) {
    console.log(error.response?.data?.message || error.message);
  }
};

export const fetchCities = async () => {
  const data = await baseQuery({ url: "/api/system/cities" });
  return data;
};

export const fetchNotificationPageList = async () => {
  const data = await baseQuery({ url: "/api/system/notificationpagelist" });
  return data;
};

export const fetchPrivilegeCategoriesLookupList = async () => {
  const data = await baseQuery({ url: "/api/privilegecategories" });
  return data;
};
export const fetchVehicleBrandLookupList = async () => {
  const data = await baseQuery({ url: "/api/vehiclebrands/lookup" });
  return data;
};
export const fetchPrivilegePriceLookupList = async (id: string) => {
  const data = await baseQuery({
    url: "/api/privilegeprices/getbyprivilegeid/" + id,
  });
  return data;
};
