import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/saga";
import LayoutSaga from "./layout/saga";
import lookupSaga from "./lookup/saga";
import privilegeSaga from "./privilege/saga";
import privilegecategorySaga from "./privilegecategory/saga";
import packageSaga from "./packages/saga";
import sliderSaga from "./slider/saga";
import supplierSaga from "./supplier/saga";
import supplierlocationSaga from "./supplierlocations/saga";
import privilegepricesSaga from "./privilegeprices/saga";
import suppliertypeSaga from "./suppliertypes/saga";
import supplierdocumentSaga from "./supplierdocuments/saga";
import customerSaga from "./customers/saga";
import campaignsSaga from "./campaigns/saga";
import customeraddresssaga from "./customeraddress/saga";
import customerdiscountsaga from "./customerdiscounts/saga";
import privilegecouponSaga from "./privilegecoupons/saga";
import vehiclebodytypeSaga from "./vehiclebodytype/saga";
import vehiclecolorSaga from "./vehiclecolor/saga";
import vehiclegeartypeSaga from "./vehiclegeartype/saga";
import vehiclefueltypeSaga from "./vehiclefueltype/saga";
import customereducationstatussaga from "./customereducationstatus/saga";
import customerjobsaga from "./customerjob/saga";
import packagediscountdefinitionsSaga from "./packagediscountdefinitions/saga";
import packageGiftSaga from "./packagegift/saga";
import customerfavoritessaga from "./customerfavorites/saga";
import customervehiclessaga from "./customervehicles/saga";
import couponSaga from "./coupons/saga";
import customerprivilegesaga from "./customerprivilege/saga";
import supplierservicetypeSaga from "./supplierservicetypes/saga";
import notificationSaga from "./notifications/saga";
import vehiclechangeStatusSaga from "./vehiclechangestatus/saga";
import ticketSagas from "./ticket/saga";
import faqsSaga from "./faqs/saga";
import invoicePackageSaga from "./package-invoices/saga";
import invoicePrivilegeSaga from "./privilege-invoices/saga";
import ticketSubjectSaga from "./ticket-subject/saga";
import databankSaga from "./databank/saga";
import databankcategorySaga from "./databank-category/saga";
import ticketSubjectDetailSaga from "./ticket-subject-details/saga";
import ticketSubjectDetailInfoSaga from "./ticket-subject-details-info/saga";
import faqscategorySaga from "./faqs-category/saga";
import websliderSaga from "./web-slider/saga";
import webSaga from "./web/saga";
import contactcategorySaga from "./web-contact-categories/saga";
import MembershipcanceltypeSaga from "./membership-cancel-types/saga";
import MembershipcancelSaga from "./membership-cancel-request/saga";
import userSaga from "./user/saga";
import languageSaga from "./languages/saga";
import agreementSaga from "./agreement/saga";
import privilegetermsSaga from "./privilegeterms/saga";
import privilegesupplierlocationsSaga from "./privilegelocations/saga";
import dashboardSaga from "./dashboard/saga";
import reminderSettingsSaga from "./reminder-settings/saga";
import privilegebuttonsSaga from "./privilegebuttons/saga";
import customercalllogsaga from "./customercalllog/saga";
import customercalllogsSaga from "./customer-calllog/saga";
import privilegecardsSaga from "./privilegecard/saga";
import emailTemplatesSaga from "./emailTemplates/saga";
import emailsSaga from "./emails/saga";
import informedRecipientsSaga from "./informedRecipients/saga";
import socialmedialinksSaga from "./socialMedias/saga";
import localizationsSaga from "./localizations/saga";
import numberofinstallmentsSaga from "./paymentInstallment/saga";
import calllogchannelsSaga from "./calllog-channel/saga";
import tireBrandSaga from "./tires/brands/saga";
import tireDiameterSaga from "./tires/diameters/saga";
import tireServiceSaga from "./tires/services/saga";
import tireSidewallWidthSaga from "./tires/sidewallwidths/saga";
import tireSupplierSaga from "./tires/suppliers/saga";
import tireTreadWidthSaga from "./tires/treadwidths/saga";
import tiresSaga from "./tires/tires/saga";
import tireSupplierInfoSaga from "./tires/supplierinfos/saga";
import tiresalesSaga from "./tires/tireSales/saga";
import containsprivilegesSaga from "./privilegecontain/saga";
import notificationmessagesSaga from "./notification-messages/saga";
import phoneNumberSaga from "./phoneNumbers/saga";
import orderCancellationReasonSaga from "./orders/order-cancellation-reasons/saga";
import orderRefundReasonSaga from "./orders/order-refund-reasons/saga";
import productCategorySagaSaga from "./orders/product-categories/saga";
import productCategoryBannerSagaSaga from "./orders/product-category-banners/saga";
import orderShippingCompanySaga from "./orders/order-shipping-companies/saga";
import productSaga from "./orders/products/saga";
import productImageSaga from "./orders/products-images/saga";
import productCompatibleBrandSaga from "./orders/product-compatible-brands/saga";
import orderSaga from "./orders/orders/saga";
import blacklistsSaga from "./black-lists/saga";
import socketNotificationSaga from "./socketNotification/saga";
import customergroupsaga from "./customergroup/saga";
import systemLogSaga from "./system-logs/saga";
import ticketchannelsSaga from "./ticket-channel/saga";
import notificationActionSaga from "./notification-actions/saga";
import plannedNotificationSaga from "./notification-planned/saga";
import plannedNotificationButtonSaga from "./notification-planned-buttons/saga";
import roleSaga from "./roles/saga";
import permissionSaga from "./permissions/saga";
import CorporateClientsSaga from "./corporate-clients/saga";
import CorporateCompaniesSaga from "./corporate-companies/saga";
import CorporateContractsSaga from "./corporate-contracts/saga";
import CorporateLocationsSaga from "./corporate-locations/saga";
import CorporateDepartmentsSaga from "./corporate-departments/saga";
import reportSaga from "./reports/saga";
import vehicleBrandsSaga from "./maintenance/vehicle-brands/saga";
import vehicleModelsSaga from "./maintenance/vehicle-models/saga";
import maintenanceCategorySaga from "./maintenance/maintenance-categories/saga";
import serviceMaintenanceCardsSaga from "./maintenance/service-maintenance-cards/saga";
import maintenanceDraftsSaga from "./maintenance/maintenance-drafts/saga";
import packageExtraSaga from "./packageextra/saga";
import systemreminderusersSaga from "./system-reminder-users/saga";
import appversionsSaga from "./appversions/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    ...authSaga,
    ...lookupSaga,
    ...languageSaga,
    ...supplierSaga,
    ...sliderSaga,
    ...privilegeSaga,
    ...privilegecategorySaga,
    ...packageSaga,
    ...privilegepricesSaga,
    ...suppliertypeSaga,
    ...supplierlocationSaga,
    ...supplierdocumentSaga,
    ...customerSaga,
    ...campaignsSaga,
    ...customeraddresssaga,
    ...customerdiscountsaga,
    ...privilegecouponSaga,
    ...vehiclebodytypeSaga,
    ...vehiclecolorSaga,
    ...vehiclegeartypeSaga,
    ...vehiclefueltypeSaga,
    ...customereducationstatussaga,
    ...customerjobsaga,
    ...packagediscountdefinitionsSaga,
    ...packageGiftSaga,
    ...customerfavoritessaga,
    ...customervehiclessaga,
    ...couponSaga,
    ...customerprivilegesaga,
    ...supplierservicetypeSaga,
    ...notificationSaga,
    ...vehiclechangeStatusSaga,
    ...ticketSagas,
    ...faqsSaga,
    ...faqscategorySaga,
    ...invoicePackageSaga,
    ...invoicePrivilegeSaga,
    ...ticketSubjectSaga,
    ...databankSaga,
    ...databankcategorySaga,
    ...ticketSubjectDetailSaga,
    ...ticketSubjectDetailInfoSaga,
    ...websliderSaga,
    ...webSaga,
    ...contactcategorySaga,
    ...MembershipcanceltypeSaga,
    ...MembershipcancelSaga,
    ...userSaga,
    ...agreementSaga,
    ...privilegetermsSaga,
    ...privilegesupplierlocationsSaga,
    ...dashboardSaga,
    ...reminderSettingsSaga,
    ...privilegebuttonsSaga,
    ...customercalllogsaga,
    ...customercalllogsSaga,
    ...privilegecardsSaga,
    ...emailTemplatesSaga,
    ...emailsSaga,
    ...informedRecipientsSaga,
    ...socialmedialinksSaga,
    ...localizationsSaga,
    ...numberofinstallmentsSaga,
    ...calllogchannelsSaga,
    ...tireBrandSaga,
    ...tireDiameterSaga,
    ...tireServiceSaga,
    ...tireSidewallWidthSaga,
    ...tireSupplierSaga,
    ...tireTreadWidthSaga,
    ...tiresSaga,
    ...tireSupplierInfoSaga,
    ...tiresalesSaga,
    ...containsprivilegesSaga,
    ...notificationmessagesSaga,
    ...phoneNumberSaga,
    ...orderCancellationReasonSaga,
    ...orderRefundReasonSaga,
    ...productCategorySagaSaga,
    ...productCategoryBannerSagaSaga,
    ...orderShippingCompanySaga,
    ...productSaga,
    ...productImageSaga,
    ...productCompatibleBrandSaga,
    ...orderSaga,
    ...blacklistsSaga,
    ...customergroupsaga,
    ...socketNotificationSaga,
    ...systemLogSaga,
    ...ticketchannelsSaga,
    ...notificationActionSaga,
    ...plannedNotificationSaga,
    ...plannedNotificationButtonSaga,
    ...roleSaga,
    ...permissionSaga,

    ...CorporateClientsSaga,
    ...CorporateCompaniesSaga,
    ...CorporateContractsSaga,
    ...CorporateLocationsSaga,
    ...CorporateDepartmentsSaga,
    ...reportSaga,

    ...vehicleBrandsSaga,
    ...vehicleModelsSaga,
    ...maintenanceCategorySaga,
    ...serviceMaintenanceCardsSaga,
    ...maintenanceDraftsSaga,
    ...packageExtraSaga,
    ...systemreminderusersSaga,
    ...appversionsSaga,
  ]);
}
