import { forwardRef } from "react";
import DataGrid, {
  Column,
  Editing,
  Item,
  Lookup,
  Paging,
  Toolbar,
} from "devextreme-react/data-grid";

const CreateCouponIncludedPackage = forwardRef<any, any>(
  (
    { data, packages, discountTypes, onUpdated, onRemoved, onInserted },
    ref
  ) => {
    return (
      <>
        <DataGrid
          key={"couponpackagegrid"}
          dataSource={data ?? []}
          keyExpr="Id"
          allowColumnResizing
          showBorders={true}
          ref={ref}
          onRowInserted={e => onInserted(e)}
          onRowUpdated={e => onUpdated(e)}
          onRowRemoved={e => onRemoved(e)}
        >
          <Toolbar>
            <Item location="before" text="Dahili Paketler" />
            <Item location="after" name="addRowButton" />
          </Toolbar>
          <Paging enabled={false} />
          <Editing
            mode="form"
            form={{
              validationGroup: "CreateCouponPackage",
            }}
            allowUpdating={false}
            allowAdding={true}
            allowDeleting={true}
          />
          <Column dataField="PackageId" caption="Paket">
            <Lookup
              dataSource={packages}
              valueExpr="Id"
              displayExpr="PackageName"
            />
          </Column>
          <Column dataField="DiscountTypeId" caption="İndirim Tipi">
            <Lookup
              dataSource={discountTypes}
              valueExpr="EnumId"
              displayExpr="Name"
            />
          </Column>
          <Column dataField="DiscountValue" caption="İndirim Oranı" />
        </DataGrid>
      </>
    );
  }
);
export default CreateCouponIncludedPackage;
