import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomFormik from "src/components/Form/Formik";
import { InputType } from "src/components/Form/type";
import {
  getDiscountTypeLookupList,
  getPrivilegeUsageTypes,
  getprivilegeCouponTypes,
} from "src/store/lookup/saga";
import {
  DiscountTypeDto,
  PrivilegeCouponType,
  UsageTypeDto,
} from "src/store/lookup/type";
import { getCampaignDetails, updateCampaign } from "src/store/campaigns/saga";
import { getSupplierLookup } from "src/store/supplier/saga";
import { SupplierDto } from "src/store/supplier/type";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as Yup from "yup";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router";
import { CampaignDto } from "src/store/campaigns/type";
import Loader from "src/components/Loader";
import { isLoading } from "src/store/loader";
import { PrivilegeCategoryDto } from "src/store/privilegecategory/type";
import { getPrivilegeCategoryList } from "src/store/privilegecategory/saga";
import { PrivilegeUsageTypeEnum } from "src/store/lookup/enum";
import {
  getPrivilegeLinkShowingTypeList,
  getPrivilegeList,
} from "src/store/privilege/saga";
import {
  PrivilegeDto,
  PrivilegeLinkShowingTypeDto,
} from "src/store/privilege/type";
import { SupplierConsts } from "src/store/supplier/consts";
const AlwaysScrollToBottom = () => {
  const elementRef = useRef(null);
  useEffect(() =>
    //@ts-ignore
    elementRef.current.scrollIntoView()
  );
  return <div ref={elementRef} />;
};
const EditCampaignPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const [campaigns, setCampaign] = useState<CampaignDto>();
  const [categories, setCategories] = useState<PrivilegeCategoryDto[]>([]);
  const [suppliers, setSuppliers] = useState<SupplierDto[]>([]);
  const [discountTypes, setDiscountTypes] = useState<DiscountTypeDto[]>([]);
  const loader = useSelector(isLoading);
  const [usageTypes, setUsageTypes] = useState<UsageTypeDto[]>([]);
  const [couponTypes, setCouponTypes] = useState<PrivilegeCouponType[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [linkTypes, setLinkTypes] = useState<PrivilegeLinkShowingTypeDto[]>([]);

  useEffect(() => {
    dispatch(
      getPrivilegeLinkShowingTypeList({
        payload: {
          onSuccess: (msg, payload) => setLinkTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getPrivilegeList({
        payload: {
          onSuccess: (msg, payload) => setPrivileges(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getprivilegeCouponTypes({
        payload: {
          onSuccess: (msg, payload) => setCouponTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getPrivilegeUsageTypes({
        payload: {
          onSuccess: (msg, payload) => setUsageTypes(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getCampaignDetails({
        payload: {
          onSuccess: (msg, payload) => setCampaign(payload),
          onError: () => {},
        },
        id: id,
      })
    );
    dispatch(
      getPrivilegeCategoryList({
        payload: {
          onSuccess: (msg, payload) => setCategories(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getSupplierLookup({
        payload: {
          onSuccess: (msg, payload) => setSuppliers(payload),
          onError: () => {},
        },
      })
    );
    dispatch(
      getDiscountTypeLookupList({
        payload: {
          onSuccess: (msg, payload) => setDiscountTypes(payload),
          onError: () => {},
        },
      })
    );
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <AlwaysScrollToBottom />
      <div className="page-content">
        <MetaTags>
          <title>
            {t("Dashboard")}
            {t("| Servis Plan")}
          </title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={t("Campaigns")}
            breadcrumbItem={t("Edit Campaign")}
          />

          <Row className="mt-4">
            {loader && <Loader />}
            {campaigns && (
              <CustomFormik
                loader
                createButtonClass="w-100"
                initialValues={{
                  ConnectedPrivilegeId: campaigns.ConnectedPrivilegeId ?? "",
                  PrivilegeUsageTypeId: campaigns.PrivilegeUsageTypeId ?? "",
                  UsageValue: campaigns.UsageValue ?? "",
                  Id: campaigns.Id,
                  PrivilegeCategoryId: campaigns.PrivilegeCategoryId ?? "",
                  SupplierId: campaigns.SupplierId ?? "",
                  Title: campaigns.Title ?? "",
                  ShortDescription: campaigns.ShortDescription ?? "",
                  Description: campaigns.Description ?? "",
                  StartDate: campaigns.StartDate ?? "",
                  EndDate: campaigns.EndDate ?? "",
                  Limited: campaigns.Limited,
                  CouponCount: campaigns.CouponCount ?? 0,
                  Active: campaigns.Active,
                  CurrentImageFile: campaigns.Image ?? "",
                  ImageFile: "",
                  ResponsiveImageFile: "",
                  ShowWebsite: campaigns.ShowWebsite ?? false,
                  CurrentResponsiveImageFile: campaigns.ResponsiveImage ?? "",
                  TermsOfService: campaigns.TermsOfService ?? "",
                  CanBeUsedMoreThanOnce: campaigns.CanBeUsedMoreThanOnce,
                  DiscountTypeId: campaigns.DiscountTypeId ?? "",
                  DiscountValue: campaigns.DiscountValue ?? "",
                  IncludablePackage: campaigns.IncludablePackage,
                  Sellable: campaigns.Sellable,
                  ShowOnMobile: campaigns.ShowOnMobile ?? false,
                  PrivilegeCouponTypeId: campaigns.PrivilegeCouponTypeId ?? 2,
                  CouponLenght: campaigns.CouponLenght ?? 10,
                  ShowPhoneNumber: campaigns.ShowPhoneNumber ?? false,
                  PhoneNumber: campaigns.PhoneNumber ?? "",
                  ShowUsageInformationText: campaigns.ShowUsageInformationText,
                  UsageInformationText: campaigns.UsageInformationText ?? "",
                  ShowTerms: campaigns.ShowTerms,

                  SendNotificationMembers: false,
                  SendNotificationAllCustomer: false,
                  SendNotificationForFavorites: false,
                  PrivilegeLinkShowingTypeId:
                    campaigns.PrivilegeLinkShowingTypeId ?? "",
                }}
                onSubmit={values => {
                  console.log(values);
                  dispatch(
                    updateCampaign({
                      payload: {
                        onSuccess: (message, payload) => {
                          toast.success(message, { theme: "colored" });
                          return setCampaign(payload);
                        },
                        onError: message => {
                          toast.error(message, {
                            theme: "colored",
                          });
                        },
                      },
                      body: values,
                      id: campaigns.Id,
                    })
                  );
                }}
                validationSchema={Yup.object().shape({
                  PrivilegeUsageTypeId: Yup.number().required(t("Required")),
                  PrivilegeCouponTypeId: Yup.number().required(t("Required")),
                  PrivilegeCategoryId: Yup.string().required("Zorunlu"),
                  SupplierId: Yup.string().required("Zorunlu"),
                  ShortDescription: Yup.string().required("Zorunlu"),
                  Title: Yup.string().required("Zorunlu"),
                  Description: Yup.string().required("Zorunlu"),
                  StartDate: Yup.string().required("Zorunlu"),
                  EndDate: Yup.string().required("Zorunlu"),
                  DiscountTypeId: Yup.number().required("Zorunlu"),
                  DiscountValue: Yup.number().required("Zorunlu"),
                  CouponLenght: Yup.number()
                    .min(4, "Minimum değeri 4 olabilir")
                    .max(12, "Maximum değeri 12 olabilir")
                    .required("Zorunlu"),
                  PhoneNumber: Yup.string().when("ShowPhoneNumber", {
                    is: (id: boolean) => id,
                    then: Yup.string().required(t("Required")),
                  }),
                })}
                inputs={[
                  {
                    field: "PrivilegeCategoryId",
                    inputType: InputType.multiselect,
                    label: t("Kategori"),
                    lookup: {
                      data: categories,
                      labelKey: "CategoryName",
                      valueKey: "Id",
                      placeholder: t("Kategori Seçiniz"),
                    },
                    col: 4,
                  },
                  {
                    col: 4,
                    field: "SupplierId",
                    inputType: InputType.multiselect,
                    label: t("Supplier"),
                    lookup: {
                      data: suppliers,
                      labelKey: "Name",
                      valueKey: "Id",
                      placeholder: t("Choose Supplier"),
                    },
                  },
                  {
                    col: 4,
                    field: "ConnectedPrivilegeId",
                    inputType: InputType.multiselect,
                    label: t("Bağlı Hizmet"),
                    lookup: {
                      data: privileges,
                      labelKey: "Title",
                      valueKey: "Id",
                      placeholder: t("Bağlı Hizmet Seçiniz"),
                    },
                  },
                  {
                    field: "Title",
                    label: t("Title"),
                  },
                  {
                    field: "ShortDescription",
                    label: t("Short Description"),
                    inputType: InputType.multilinetext,
                  },
                  {
                    field: "Description",
                    label: t("Description"),
                    inputType: InputType.multilinetext,
                  },

                  {
                    field: "PrivilegeCouponTypeId",
                    inputType: InputType.multiselect,
                    label: t("Kupon Tipi"),
                    lookup: {
                      data: couponTypes,
                      labelKey: "Name",
                      valueKey: "EnumId",
                    },
                    col: 6,
                  },
                  {
                    field: "CouponLenght",
                    label: t("Kupon Uzunluğu"),
                    inputType: InputType.number,
                    min: 4,
                    max: 12,
                    col: 6,
                  },
                  {
                    field: "StartDate",
                    label: t("Start Date"),
                    inputType: InputType.datetime,
                    col: 6,
                  },
                  {
                    field: "EndDate",
                    label: t("End Date"),
                    inputType: InputType.datetime,
                    col: 6,
                  },
                  {
                    field: "PrivilegeUsageTypeId",
                    inputType: InputType.multiselect,
                    label: t("Usage Type"),
                    lookup: {
                      data: usageTypes,
                      labelKey: "Name",
                      valueKey: "EnumId",
                      placeholder: t("Choose Usage Type"),
                      disabledItems: values =>
                        values.SupplierId !== SupplierConsts.Witty
                          ? usageTypes
                              .filter(
                                x => x.EnumId === PrivilegeUsageTypeEnum.Witty
                              )
                              .map(x => x.EnumId)
                          : [],
                    },
                    col: 6,
                    onChange(value, item, setFieldValue, values) {
                      if (
                        !(
                          value === PrivilegeUsageTypeEnum.Kupon ||
                          value === PrivilegeUsageTypeEnum.QR
                        )
                      ) {
                        setFieldValue("Limited", false);
                        setFieldValue("CouponCount", 0);
                      }
                    },
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                          PrivilegeUsageTypeEnum.Kupon ||
                        values.PrivilegeUsageTypeId ===
                          PrivilegeUsageTypeEnum.QR
                      ),
                    field: "Limited",
                    label: t("Limited"),
                    col: 2,
                    inputType: InputType.checkbox,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                          PrivilegeUsageTypeEnum.Kupon && values.Limited
                      ),
                    field: "CouponCount",
                    label: t("Coupon Count"),
                    inputType: InputType.number,
                    col: 4,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                          PrivilegeUsageTypeEnum.QR && values.Limited
                      ),
                    field: "CouponCount",
                    label: t("QR Count"),
                    inputType: InputType.number,
                    col: 4,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.Link
                      ),
                    field: "UsageValue",
                    label: t("Link"),
                    col: 6,
                  },
                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.Link
                      ),
                    field: "PrivilegeLinkShowingTypeId",
                    label: t("Link Gösterimi"),
                    inputType: InputType.multiselect,
                    col: 12,
                    lookup: {
                      data: linkTypes,
                      labelKey: "Name",
                      valueKey: "EnumId",
                      placeholder: t("Link Gösterim Tipi"),
                    },
                  },

                  {
                    hide: values =>
                      !(
                        values.PrivilegeUsageTypeId ===
                        PrivilegeUsageTypeEnum.SabitKod
                      ),
                    field: "UsageValue",
                    label: t("Sabit Kod"),
                    col: 6,
                  },

                  {
                    col: 6,
                    field: "DiscountTypeId",
                    inputType: InputType.multiselect,
                    label: t("Discount Type"),
                    lookup: {
                      data: discountTypes,
                      labelKey: "Name",
                      valueKey: "EnumId",
                      placeholder: t("Choose Discount Type"),
                    },
                  },
                  {
                    field: "DiscountValue",
                    label: t("Discount Value"),
                    inputType: InputType.number,
                    col: 6,
                  },
                  {
                    field: "ShowPhoneNumber",
                    label: t("Telefon Numarası Göster"),
                    inputType: InputType.checkbox,
                    col: 12,
                  },
                  {
                    field: "PhoneNumber",
                    label: t("PhoneNumber"),
                    col: 12,
                    hide: values => !values.ShowPhoneNumber,
                  },
                  {
                    field: "TermsOfService",
                    label: t("TermsOfService"),
                    inputType: InputType.multilinetext,
                  },
                  {
                    field: "CanBeUsedMoreThanOnce",
                    label: t("Can Be Used More Than Once"),

                    col: 3,
                    inputType: InputType.checkbox,
                  },

                  {
                    field: "Active",
                    label: t("Active"),
                    col: 2,
                    checkedLabel: t("Active"),
                    uncheckedLabel: t("Pasive"),
                    inputType: InputType.checkbox,
                  },

                  {
                    field: "IncludablePackage",
                    label: t("Includable Package"),
                    col: 2,
                    inputType: InputType.checkbox,
                  },

                  {
                    field: "ShowOnMobile",
                    label: t("Show On Mobile"),
                    col: 2,
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowWebsite",
                    label: t("Show On Web"),
                    col: 2,
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowTerms",
                    label: t("Kullanım Koşullarını Göster"),
                    col: 2,
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "ShowUsageInformationText",
                    label: t("Kullanım Şeklini Göster"),
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "UsageInformationText",
                    label: t("Kullanım Şekli"),
                    height: "200px",
                    inputType: InputType.editor,
                  },

                  {
                    field: "ImageFile",
                    label: t("ImageFile Image"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      linkField: "CurrentImageFile",
                      accept: ["image/jpeg", "image/png"],
                      multiple: false,
                      title: t("Please Upload Image"),
                    },
                  },
                  {
                    field: "ResponsiveImageFile",
                    label: t("ImageFile Image"),
                    inputType: InputType.fileUpload,
                    fileUpload: {
                      linkField: "CurrentResponsiveImageFile",
                      accept: ["image/jpeg", "image/png"],
                      multiple: false,
                      title: t("Responsive Fotoğraf Yükleyiniz"),
                    },
                  },
                  {
                    field: "SendNotificationForFavorites",
                    label: t("Favorilere Eklemiş Olanlara Bildirim Gönder"),
                    inputType: InputType.checkbox,
                  },
                  {
                    field: "SendNotificationMembers",
                    label: t("Üyelere Bildirim Gönder"),
                    inputType: InputType.checkbox,
                    onChange(value, item, setFieldValue, values) {
                      if (
                        value === true &&
                        values.SendNotificationAllCustomer === true
                      ) {
                        setFieldValue("SendNotificationAllCustomer", false);
                      }
                    },
                  },
                  {
                    field: "SendNotificationAllCustomer",
                    label: t("Herkese Bildirim Gönder"),
                    inputType: InputType.checkbox,
                    onChange(value, item, setFieldValue, values) {
                      if (
                        value === true &&
                        values.SendNotificationMembers === true
                      ) {
                        setFieldValue("SendNotificationMembers", false);
                      }
                    },
                  },
                ]}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default EditCampaignPage;
