import { SelectPicker } from "rsuite";
import { CustomSelectPickerProps } from "../type";

const CustomSelectPicker = ({
  label,
  error,
  field,
  touched,
  ...rest
}: CustomSelectPickerProps) => {
  return (
    <div className="textOnInput my-0">
      <label
        className={error && touched ? "errorLabel customlabel" : "customlabel"}
        htmlFor={field}
        style={{ background: "white" }}
      >
        {label ? label : ""}
      </label>
      <SelectPicker
        className={
          error && touched
            ? "errorInput defaultInput form-control selectpickerInput form2-control"
            : "defaultInput form-control selectpickerInput form2-control"
        }
        {...rest}
      />
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomSelectPicker;
