import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import FormModal from "src/components/Form/FormModal";
import { InputType } from "src/components/Form/type";
import {
  getAllPrivilegeList,
  getPrivilegeLinkShowingTypeList,
} from "src/store/privilege/saga";
import {
  PrivilegeDto,
  PrivilegeLinkShowingTypeDto,
} from "src/store/privilege/type";
import {
  createPrivilegeButton,
  getPrivilegeButtonActionTypes,
} from "src/store/privilegebuttons/saga";
import { ButtonActionTypeDto } from "src/store/privilegebuttons/type";
import { getPrivilegePriceListByPrivilegeId } from "src/store/privilegeprices/saga";
import { PrivilegePriceDto } from "src/store/privilegeprices/type";
import * as Yup from "yup";

const CreatePrivilegeButtonForm = ({
  privilegeId,
  onHide,
  open,
  onSuccessClick,
}: {
  privilegeId: string;
  onSuccessClick: (message: string) => void;
  onHide: () => void;
  open: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actionTypes, setActionTypes] = useState<ButtonActionTypeDto[]>([]);
  const [prices, setPrices] = useState<PrivilegePriceDto[]>([]);
  const [privileges, setPrivileges] = useState<PrivilegeDto[]>([]);
  const [linkTypes, setLinkTypes] = useState<PrivilegeLinkShowingTypeDto[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(
        getPrivilegeLinkShowingTypeList({
          payload: {
            onSuccess: (msg, payload) => setLinkTypes(payload),
            onError: () => {},
          },
        })
      );
      dispatch(
        getAllPrivilegeList({
          payload: {
            onSuccess: (msg, py) => setPrivileges(py),
            onError: () => {},
          },
        })
      );
      dispatch(
        getPrivilegeButtonActionTypes({
          payload: {
            onSuccess: (msg, py) => setActionTypes(py),
            onError: () => {},
          },
        })
      );
    }
  }, [dispatch, open]);
  return (
    <React.Fragment>
      <FormModal
        modalProps={{
          open: open,
          onHide: onHide,
          title: t("Ekle"),
        }}
        onHide={onHide}
        loader
        initialValues={{
          PrivilegeId: privilegeId,
          ButtonName: "",
          PricePrivilegeId: "",
          Value: "",
          IconFile: "",
          ButtonActionTypeId: "",
          PrivilegeLinkShowingTypeId: "",
        }}
        onSubmit={values => {
          dispatch(
            createPrivilegeButton({
              payload: {
                onSuccess: (message, payload) => {
                  onHide();
                  onSuccessClick(message);
                },
                onError: message => {
                  toast.error(message, {
                    theme: "colored",
                  });
                },
              },
              body: values,
            })
          );
        }}
        validationSchema={Yup.object().shape({
          PrivilegeId: Yup.string().required(t("Required")),
          //  Value: Yup.string().required(t("Required")),
          ButtonActionTypeId: Yup.number().required(t("Required")),
        })}
        inputs={[
          {
            field: "ButtonName",
            label: t("Buton Adı"),
            inputType: InputType.multilinetext,
          },
          {
            field: "ButtonActionTypeId",
            inputType: InputType.multiselect,
            label: t("Aksiyon Tipi"),
            lookup: {
              data: actionTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Aksiyon Tipi Seçiniz"),
            },
          },
          {
            hide(values) {
              return !(
                values.ButtonActionTypeId === 4 ||
                values.ButtonActionTypeId === 5
              );
            },
            field: "PricePrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
            onChange(value, item, setFieldValue, values) {
              value &&
                dispatch(
                  getPrivilegePriceListByPrivilegeId({
                    payload: {
                      onSuccess: (msg, py) => setPrices(py),
                      onError: () => {},
                    },
                    id: value,
                  })
                );
            },
          },

          {
            hide(values) {
              return !(
                values.ButtonActionTypeId === 4 ||
                values.ButtonActionTypeId === 5
              );
            },
            field: "Value",
            inputType: InputType.multiselect,
            label: t("Privilege Price"),
            lookup: {
              data: prices,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Choose Privilege Price"),
            },
          },
          {
            hide(values) {
              return (
                values.ButtonActionTypeId === 4 ||
                values.ButtonActionTypeId === 5
              );
            },
            field: "Value",
            label: t("Değeri"),
          },

          {
            hide: values => !(values.ButtonActionTypeId === 1),
            field: "PrivilegeLinkShowingTypeId",
            label: t("Link Gösterimi"),
            inputType: InputType.multiselect,
            col: 12,
            lookup: {
              data: linkTypes,
              labelKey: "Name",
              valueKey: "EnumId",
              placeholder: t("Link Gösterim Tipi"),
            },
          },

          {
            field: "IconFile",
            label: t("IconFile Image"),
            inputType: InputType.fileUpload,
            fileUpload: {
              accept: ["image/svg+xml"],
              multiple: false,
              title: t("Please Upload Icon Image"),
            },
          },
        ]}
      />
    </React.Fragment>
  );
};
export default CreatePrivilegeButtonForm;
