import { forwardRef, useState } from "react";
import DataGrid, {
  Column,
  Item,
  Paging,
  Toolbar,
} from "devextreme-react/data-grid";
import { PrivilegePriceDto } from "src/store/privilegeprices/type";
import { FormModalComp } from "src/components/Form/FormModal";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Button } from "devextreme-react";
import { PrivilegeDto } from "src/store/privilege/type";
import { InputType } from "src/components/Form/type";
import { fetchPrivilegePriceLookupList } from "src/api/api";
import { v4 as uuidv4 } from "uuid";
import { confirm } from "devextreme/ui/dialog";
const CreateCouponIncludedPackage = forwardRef<any, any>(
  ({ data, privileges, onRemoved, onInserted }, ref) => {
    return (
      <>
        <DataGrid
          key={"privilegegrid"}
          dataSource={data ?? []}
          keyExpr="Id"
          allowColumnResizing
          showBorders={true}
          ref={ref}
        >
          <Toolbar>
            <Item location="before" text="Dahili Hizmetler" />
            <Item
              location="after"
              render={() => (
                <CreateCouponIncludedPrivilege
                  privileges={privileges}
                  onInserted={onInserted}
                />
              )}
            />
          </Toolbar>
          <Paging enabled={false} />
          <Column dataField="Name" caption="Dahil Hizmet Adı" />
          <Column dataField="Count" caption="Count" dataType="number"></Column>
          <Column
            dataField="ShowInformation"
            caption="Açıklamayı Göster"
            dataType="boolean"
          />
          <Column dataField="InformationText" caption="Açıklama"></Column>
          <Column
            type="buttons"
            buttons={[
              {
                text: "Sil",
                onClick: e => {
                  let confirmResult = confirm(
                    "Bu kaydı silmek istediğinize emin misiniz?",
                    ""
                  );
                  confirmResult.then(async dialogResult => {
                    if (dialogResult) {
                      onRemoved(e.row?.data);
                    }
                  });
                },
              },
            ]}
          />
        </DataGrid>
      </>
    );
  }
);
export default CreateCouponIncludedPackage;

const CreateCouponIncludedPrivilege = ({
  privileges,
  onInserted,
}: {
  onInserted: (e: any) => void;
  privileges: PrivilegeDto[];
}) => {
  const { t } = useTranslation();
  const [prices, setPrices] = useState<PrivilegePriceDto[]>([]);
  const [open, setOpen] = useState(false);
  const getPrices = async (privilegeId: string) => {
    const data = await fetchPrivilegePriceLookupList(privilegeId);
    setPrices(data.Result);
  };
  return (
    <>
      <Button icon="add" onClick={() => setOpen(true)} />
      <FormModalComp
        modalProps={{
          open: open,
          onHide: () => setOpen(false),
        }}
        onHide={() => setOpen(false)}
        loader
        initialValues={{
          Id: uuidv4(),
          PrivilegePricePrivilegeId: "",
          PrivilegePriceId: "",
          InformationText: "",
          Name: "",
          Count: 0,
          ShowInformation: false,
        }}
        onSubmit={values => {
          onInserted(values);
          setOpen(false);
        }}
        validationSchema={Yup.object().shape({
          Count: Yup.number().required(t("Required")),
          PrivilegePricePrivilegeId: Yup.string().required(t("Required")),
          PrivilegePriceId: Yup.string().required(t("Required")),
          Name: Yup.string().required(t("Required")),
        })}
        inputs={[
          {
            field: "PrivilegePricePrivilegeId",
            inputType: InputType.multiselect,
            label: t("Privilege"),
            lookup: {
              data: privileges,
              labelKey: "Title",
              valueKey: "Id",
              placeholder: t("Choose Privilege"),
            },
            onChange: async (value, item, setFieldValue, values) => {
              item && item.Title && setFieldValue("Name", item.Title);
              value && (await getPrices(value));
            },
          },
          {
            field: "PrivilegePriceId",
            inputType: InputType.multiselect,
            label: t("Privilege Price"),
            lookup: {
              data: prices,
              labelKey: "Name",
              valueKey: "Id",
              placeholder: t("Choose Privilege Price"),
            },
          },
          {
            field: "Name",
            label: t("Mobilde Görünecek Ad"),
          },
          {
            field: "Count",
            label: t("Adet"),
            inputType: InputType.number,
          },

          {
            field: "ShowInformation",
            label: t("Açıklamayı göster"),
            inputType: InputType.checkbox,
          },
          {
            field: "InformationText",
            label: t("Açıklama"),
            inputType: InputType.multilinetext,
          },
        ]}
      />
    </>
  );
};
