import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { AppVersionDto } from "./type";
import { SagaActionParams, SagaGenericParams } from "src/store/base/types/saga";
import {
  PostSagaGenericParams,
  postSaga,
  putSaga,
} from "src/store/base/request/post";
import { getListSaga } from "src/store/base/request/get";
import { deleteSaga } from "src/store/base/request/delete";

export const getAppVersionList = createAction(
  "GET_APP_VERSION_LIST",
  ({ payload, params }: SagaActionParams<AppVersionDto[]>) => ({
    payload,
    url: "/api/appversions" + (params ? params : ""),
  })
);
export const getAppVersionDetails = createAction(
  "GET_APP_VERSION_DETAILS",
  ({ payload, id }: SagaActionParams<AppVersionDto>) => ({
    payload,
    url: "/api/appversions/" + id,
  })
);
export const createAppVersion = createAction(
  "CREATE_APP_VERSION",
  ({ payload, body }: SagaActionParams<AppVersionDto>) => ({
    payload,
    url: "/api/appversions",
    body,
  })
);
export const updateAppVersion = createAction(
  "UPDATE_APP_VERSION_DETAILS",
  ({ payload, body, id }: SagaActionParams<AppVersionDto>) => ({
    payload,
    url: "/api/appversions/" + id,
    body,
  })
);
export const deleteAppVersion = createAction(
  "DELETE_APP_VERSION_DETAILS",
  ({ payload, body, id }: SagaActionParams<AppVersionDto>) => ({
    payload,
    url: "/api/appversions/" + id,
    body,
  })
);

const appversionsSaga = [
  takeLatest(
    getAppVersionList.toString(),
    (payload: SagaGenericParams<AppVersionDto[]>) =>
      getListSaga<AppVersionDto[]>(payload)
  ),
  takeLatest(
    getAppVersionDetails.toString(),
    (payload: SagaGenericParams<AppVersionDto>) =>
      getListSaga<AppVersionDto>(payload)
  ),
  takeLatest(
    createAppVersion.toString(),
    (payload: PostSagaGenericParams<AppVersionDto>) =>
      postSaga<AppVersionDto>(payload)
  ),

  takeLatest(
    updateAppVersion.toString(),
    (payload: PostSagaGenericParams<AppVersionDto>) =>
      putSaga<AppVersionDto>(payload)
  ),
  takeLatest(
    deleteAppVersion.toString(),
    (payload: SagaGenericParams<AppVersionDto>) =>
      deleteSaga<AppVersionDto>(payload)
  ),
];

export default appversionsSaga;
