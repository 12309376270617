import { takeLatest } from "redux-saga/effects";
import { createAction } from "redux-smart-actions";
import { deleteSaga } from "../base/request/delete";
import { getListSaga } from "../base/request/get";
import { postSaga, PostSagaGenericParams, putSaga } from "../base/request/post";
import { SagaActionParams, SagaGenericParams } from "../base/types/saga";
import {
  PrivilegeDto,
  PrivilegeIncludedBrandDto,
  PrivilegeLinkShowingTypeDto,
  PrivilegeTypeDto,
} from "./type";
import { BodyType } from "../base/request/request";

export const getAllPrivilegeList = createAction(
  "GET_ALL_PRIvilege_LIST",
  ({ payload, params }: SagaActionParams<PrivilegeDto[]>) => ({
    payload,
    url: "/api/privileges/getallprivilages?Skip=0&Take=10000",
  })
);
export const getPrivilegeTypes = createAction(
  "GET_PRIVILIGE_TYPES",
  ({ payload }: SagaActionParams<PrivilegeTypeDto[]>) => ({
    payload,
    url: "/api/system/privilege-types",
  })
);
export const getPrivilegeList = createAction(
  "GET_PRIvilege_LIST",
  ({ payload, params }: SagaActionParams<PrivilegeDto[]>) => ({
    payload,
    url: "/api/privileges" + (params ? params : ""),
  })
);
export const getPrivilegeListForCustomerFilter = createAction(
  "GET_PRIVILEGE_LIST_FOR_CUSTOMER_FILTER",
  ({ payload }: SagaActionParams<PrivilegeDto[]>) => ({
    payload,
    url: "/api/customers/privileges",
  })
);
export const getPrivilegeListByCategoryId = createAction(
  "GET_PRIvilege_LIST_BY_CATEGORY_ID",
  ({ payload, id, params }: SagaActionParams<PrivilegeDto[]>) => ({
    payload,
    url: "/api/privileges/bycategoryId/" + id + (params ? params : ""),
  })
);
export const changePrivilegeOrder = createAction(
  "CHANGE_PRIVILEGE_ORDER",
  ({ payload, body }: SagaActionParams<PrivilegeDto>) => ({
    payload,
    body,
    url: "/api/privileges/order",

    bodyType: BodyType.raw,
  })
);
export const updatePrivilegePriority = createAction(
  "UPDATE_PRIVILIGE_PRIORITY",
  ({ payload, body }: SagaActionParams<PrivilegeDto>) => ({
    payload,
    body,
    url: "/api/privileges/priority",
  })
);
export const changePackagePrivilegeOrder = createAction(
  "CHANGE_PACKAGE_PRIVILEGE_ORDER_AA_LIST",
  ({ payload, body }: SagaActionParams<PrivilegeDto>) => ({
    payload,
    body,
    url: "/api/packageincludeprivilages/order",

    bodyType: BodyType.raw,
  })
);
export const getPrivilegeDetails = createAction(
  "GET_PRIVILEGE_DETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeDto>) => ({
    payload,
    url: "/api/privileges/" + id,
  })
);
export const createPrivilege = createAction(
  "CREATE_PRIVILEGE_DETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeDto>) => ({
    payload,
    url: "/api/privileges",
    body,
    lng: "en-US",
  })
);
export const updatePrivilege = createAction(
  "UPDATE_PRIVILEGE_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeDto>) => ({
    payload,
    url: "/api/privileges/" + id,
    body,
  })
);
export const deletePrivilege = createAction(
  "DELETE_PRIVILEGE_DETAILS",
  ({ payload, body, id }: SagaActionParams<PrivilegeDto>) => ({
    payload,
    url: "/api/privileges/" + id,
    body,
  })
);
export const updatePrivilegeAssignedType = createAction(
  "UPDATE_PRIVILIGE_ASSIGNED_TYPE",
  ({ payload, body, id }: SagaActionParams<PrivilegeDto>) => ({
    payload,
    url: "/api/privileges/update-privilege-types/" + id,
    body,
    bodyType: BodyType.raw,
  })
);

//BRANDS

export const createPrivilegeIncludedBrands = createAction(
  "CREATE_PRIVILEGE_INCLUDED_BRAND_DETAILS",
  ({ payload, body }: SagaActionParams<PrivilegeIncludedBrandDto>) => ({
    payload,
    url: "/api/privilegeincludedbrands",
    body,
  })
);
export const deletePrivilegeIncludedBrands = createAction(
  "DELETE_PRIVILEGE_INCLUDED_BRAND_DETAILS",
  ({ payload, id }: SagaActionParams<PrivilegeIncludedBrandDto>) => ({
    payload,
    url: "/api/privilegeincludedbrands/" + id,
  })
);
export const getPrivilegeLinkShowingTypeList = createAction(
  "GET_PRIVILEGE_LINK_SHOWING_TYPE_LIST",
  ({ payload, id }: SagaActionParams<PrivilegeLinkShowingTypeDto[]>) => ({
    payload,
    url: "/api/system/privilege-link-showing-types",
  })
);

const opportunitySaga = [
  takeLatest(
    getPrivilegeList.toString(),
    (payload: SagaGenericParams<PrivilegeDto[]>) =>
      getListSaga<PrivilegeDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeListByCategoryId.toString(),
    (payload: SagaGenericParams<PrivilegeDto[]>) =>
      getListSaga<PrivilegeDto[]>(payload)
  ),
  takeLatest(
    getAllPrivilegeList.toString(),
    (payload: SagaGenericParams<PrivilegeDto[]>) =>
      getListSaga<PrivilegeDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeTypes.toString(),
    (payload: SagaGenericParams<PrivilegeDto>) =>
      getListSaga<PrivilegeDto>(payload)
  ),
  takeLatest(
    getPrivilegeListForCustomerFilter.toString(),
    (payload: SagaGenericParams<PrivilegeDto[]>) =>
      getListSaga<PrivilegeDto[]>(payload)
  ),
  takeLatest(
    getPrivilegeDetails.toString(),
    (payload: SagaGenericParams<PrivilegeDto>) =>
      getListSaga<PrivilegeDto>(payload)
  ),
  takeLatest(
    createPrivilege.toString(),
    (payload: PostSagaGenericParams<PrivilegeDto>) =>
      postSaga<PrivilegeDto>(payload)
  ),
  takeLatest(
    updatePrivilegeAssignedType.toString(),
    (payload: PostSagaGenericParams<PrivilegeDto>) =>
      putSaga<PrivilegeDto>(payload)
  ),
  takeLatest(
    updatePrivilegePriority.toString(),
    (payload: PostSagaGenericParams<PrivilegeDto>) =>
      putSaga<PrivilegeDto>(payload)
  ),
  takeLatest(
    updatePrivilege.toString(),
    (payload: PostSagaGenericParams<PrivilegeDto>) =>
      putSaga<PrivilegeDto>(payload)
  ),
  takeLatest(
    changePackagePrivilegeOrder.toString(),
    (payload: PostSagaGenericParams<PrivilegeDto>) =>
      putSaga<PrivilegeDto>(payload)
  ),

  takeLatest(
    deletePrivilege.toString(),
    (payload: SagaGenericParams<PrivilegeDto>) =>
      deleteSaga<PrivilegeDto>(payload)
  ),

  //BRAND
  takeLatest(
    createPrivilegeIncludedBrands.toString(),
    (payload: PostSagaGenericParams<PrivilegeIncludedBrandDto>) =>
      postSaga<PrivilegeIncludedBrandDto>(payload)
  ),
  takeLatest(
    deletePrivilegeIncludedBrands.toString(),
    (payload: SagaGenericParams<PrivilegeIncludedBrandDto>) =>
      deleteSaga<PrivilegeIncludedBrandDto>(payload)
  ),
  takeLatest(
    getPrivilegeLinkShowingTypeList.toString(),
    (payload: SagaGenericParams<PrivilegeLinkShowingTypeDto[]>) =>
      getListSaga<PrivilegeLinkShowingTypeDto[]>(payload)
  ),
];

export default opportunitySaga;
