import React from "react";
import { useTranslation } from "react-i18next";
import { deleteCouponGroups } from "src/store/coupons/saga";
import { MainListPage } from "src/components/main-list-page";
import { useHistory } from "react-router";

const CouponPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const columns = [
    {
      caption: t("Name"),
      dataField: "Name",
    },
    {
      caption: t("Paket"),
      dataField: "Package",
      dataType: "boolean",
    },
    {
      caption: t("Paket İndirim Oranı"),
      dataField: "PackageDiscountRate",
    },
    {
      caption: t("Privilege"),
      dataField: "Privilege",
      dataType: "boolean",
    },
    {
      caption: t("Hizmet İndirim Oranı"),
      dataField: "PrivilegeDiscountRate",
    },
    {
      caption: t("Aktif"),
      dataField: "Active",
      dataType: "boolean",
    },
    {
      caption: t("Dahil Paketler"),
      dataField: "IncludedPackages",
      cellRender: (e: any) => (
        <>
          {e.row.data?.CouponIncludedPackages.map(
            (x: any) => x.PackageName
          )?.toString()}
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <MainListPage
        title={t("Kupon Grupları")}
        url="/api/coupongroups/devext"
        columns={columns}
        create={{
          type: "url",
          url: "/coupon-groups/create",
        }}
        edit={{
          type: "url",
          url: "/coupon-groups/edit/",
        }}
        deleteButton={{
          deleteAction: deleteCouponGroups,
        }}
        columnButtons={[
          {
            text: "Hareketler",
            onClick: e =>
              history.push("/coupon-groups/transactions/" + e.row.data.Id),
          },
        ]}
      />
    </React.Fragment>
  );
};

export default CouponPage;
