import { Button, CheckPicker, Checkbox } from "rsuite";
import { CustomCheckPickerProps } from "../type";
import { useRef } from "react";

const CustomCheckPicker = ({
  label,
  error,
  field,
  touched,
  showSelectAll,
  onSelect,
  data,
  value = [],
  onSelectValue,
  valueKey,
  labelKey,
  ...rest
}: CustomCheckPickerProps) => {
  const picker = useRef<any>();

  const handleCheckAll = (value: any, checked: boolean) => {
    onSelectValue &&
      onSelectValue(
        checked ? data.map((x: any) => x[valueKey ? valueKey : "Value"]) : []
      );
  };
  return (
    <div className="textOnInput my-0">
      <label
        className={error && touched ? "errorLabel customlabel" : "customlabel"}
        htmlFor={field}
        style={{ background: "white" }}
      >
        {label ? label : ""}
      </label>
      <CheckPicker
        data={data}
        ref={picker}
        valueKey={valueKey}
        labelKey={labelKey}
        value={value}
        onSelect={onSelectValue}
        className={
          error && touched
            ? "errorInput defaultInput form-control selectpickerInput form2-control"
            : "defaultInput form-control selectpickerInput form2-control"
        }
        renderExtraFooter={() => {
          if (showSelectAll) {
            return (
              <div
                style={{
                  padding: "10px 2px",
                  borderTop: "1px solid #e5e5e5",
                }}
              >
                <Checkbox
                  indeterminate={
                    value && value.length > 0 && value.length < data.length
                  }
                  checked={value.length === data.length}
                  onChange={handleCheckAll}
                >
                  Check all
                </Checkbox>

                <Button
                  style={{
                    float: "right",
                    marginRight: 10,
                    marginTop: 2,
                  }}
                  appearance="primary"
                  size="sm"
                  onClick={() => {
                    picker.current.close();
                  }}
                >
                  Ok
                </Button>
              </div>
            );
          }
          return null;
        }}
        {...rest}
      />
      {error && touched && <span className="errorInfo">*{error}</span>}
    </div>
  );
};
export default CustomCheckPicker;
