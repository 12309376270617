import React from "react";
import { useTranslation } from "react-i18next";
import { deleteCoupon } from "src/store/coupons/saga";
import { MainListPage } from "src/components/main-list-page";
import { Button } from "devextreme-react";
import { useHistory } from "react-router";

const CouponPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const columns = [
    {
      caption: t("Grup"),
      dataField: "CouponGroupName",
      groupIndex: 0,
    },
    {
      caption: t("Code"),
      dataField: "Code",
    },

    {
      caption: t("Name"),
      dataField: "Name",
    },
    {
      caption: t("Paket"),
      dataField: "Package",
      dataType: "boolean",
    },
    {
      caption: t("Paket İndirim Oranı"),
      dataField: "PackageDiscountRate",
    },
    {
      caption: t("Privilege"),
      dataField: "Privilege",
      dataType: "boolean",
    },
    {
      caption: t("Hizmet İndirim Oranı"),
      dataField: "PrivilegeDiscountRate",
    },
    {
      caption: t("Aktif"),
      dataField: "Active",
      dataType: "boolean",
    },
    {
      caption: t("Dahil Paketler"),
      dataField: "IncludedPackages",
      cellRender: (e: any) => (
        <>
          {e.row.data?.CouponIncludedPackages.map(
            (x: any) => x.PackageName
          )?.toString()}
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <MainListPage
        title={t("CouponListTitle")}
        url="/api/coupons/devext"
        columns={columns}
        create={{
          type: "url",
          url: "/coupons/create",
        }}
        detailUrl="/coupon/details/1/"
        edit={{
          type: "url",
          url: "/coupons/edit/",
        }}
        deleteButton={{
          deleteAction: deleteCoupon,
        }}
        gridItems={[
          {
            render: () => (
              <Button
                text="Çoklu Giriş"
                onClick={() => {
                  return history.push("/coupons/multiple");
                }}
              />
            ),
          },
        ]}
      />
    </React.Fragment>
  );
};

export default CouponPage;
