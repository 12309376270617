import React from "react";
import { useTranslation } from "react-i18next";
import { deleteSupplier } from "src/store/supplier/saga";
import { MainListPage } from "src/components/main-list-page";

const SupplierPage = () => {
  const { t } = useTranslation();
  const columns = [
    {
      caption: t("Brand"),
      dataField: "Brand",
    },
    {
      caption: t("Name"),
      dataField: "Name",
    },
    {
      caption: t("Yetkili Adı"),
      dataField: "PersonName",
    },
    {
      caption: t("Yetkili Soyadı"),
      dataField: "PersonSurname",
      renderAsync: () => "asdfasdf",
    },
    {
      caption: t("Tax Number"),
      dataField: "TaxNumber",
    },
    {
      caption: t("Tax Office"),
      dataField: "TaxOffice",
    },
    {
      caption: t("City"),
      dataField: "CityName",
    },
    {
      caption: t("Town"),
      dataField: "TownName",
    },
    {
      caption: t("Contract Start Date"),
      dataField: "ContractStartDate",
      dataType: "date",
    },
    {
      caption: t("Contract End Date"),
      dataField: "ContractEndDate",
      dataType: "date",
    },
    {
      caption: t("Aktif"),
      dataField: "Active",
      dataType: "boolean",
    },
  ];

  return (
    <React.Fragment>
      <MainListPage
        url="/api/suppliers/devexp"
        create={{
          type: "url",
          url: "/supplier/create",
        }}
        detailUrl="/supplier/details/1/"
        columns={columns}
        title={t("SupplierTitle")}
        deleteButton={{
          deleteAction: deleteSupplier,
        }}
      />
    </React.Fragment>
  );
};

export default SupplierPage;
