import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Breadcrumbs.scss";

const breadcrumbMapping: { [key: string]: string } = {
  create: "Yeni Kayıt",
  edit: "Düzenle",
  suppliers: "İş Ortakları",
  coupons: "Kuponlar",
  "coupon-groups": "Kupon Grupları",

  "link-clicks": "Link Gösterimleri",
};

const isGuid = (segment: string) => {
  const guidPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return guidPattern.test(segment);
};

const generateBreadcrumbs = (pathname: string) => {
  const segments = pathname.split("/").filter(segment => segment);
  const breadcrumbs = segments
    .map((segment, index) => {
      if (isGuid(segment)) {
        return null;
      }
      const path = `/${segments.slice(0, index + 1).join("/")}`;
      const label = breadcrumbMapping[segment] || segment;
      return { path, label };
    })
    .filter(Boolean);

  return [{ path: "/", label: "Dashboard" }, ...breadcrumbs];
};

const Breadcrumbs: React.FC<{ title?: string }> = ({ title }) => {
  const location = useLocation();
  const breadcrumbs = generateBreadcrumbs(location.pathname);

  console.log(breadcrumbs, "pathname");
  return (
    <nav aria-label="custom-breadcrumb">
      <ol className="custom-breadcrumb">
        {breadcrumbs.map((breadcrumb, index) =>
          breadcrumb != null ? (
            <li key={index} className="custom-breadcrumb-item ">
              <Link to={breadcrumb.path} className="">
                {breadcrumb.label}
              </Link>
            </li>
          ) : (
            <></>
          )
        )}
        {title && (
          <li className="custom-breadcrumb-item ">
            <Link className="" to={"/#"}>
              {title}
            </Link>
          </li>
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
