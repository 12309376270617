import { Button } from "devextreme-react";
import { useState } from "react";
import { LoadFormProps } from "..";

interface CreateGridButtonProps {
  title?: string;
  afterSubmit?: () => void;
  loadForm: (props: LoadFormProps) => any;
  visible?: boolean;
}
const CreateGridButton = ({
  title,
  afterSubmit,
  loadForm,
  visible = true,
  ...rest
}: CreateGridButtonProps) => {
  const [open, setOpen] = useState(false);
  return visible ? (
    <>
      <Button
        icon="plus"
        type="normal"
        text="Yeni Ekle"
        stylingMode="outlined"
        onClick={() => setOpen(true)}
      />

      {loadForm({
        open: open,
        onHide: () => setOpen(false),
        onSuccessClick(message) {
          return afterSubmit && afterSubmit();
        },
      })}
    </>
  ) : (
    <></>
  );
};
export default CreateGridButton;
