import React, { useEffect, useLayoutEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { isLoading } from "src/store/loader";
import Loader from "src/components/Loader";
import {
  EmailTemplateDto,
  EmailTemplateTypeDto,
} from "src/store/emailTemplates/type";
import "./style.scss";
import {
  deleteEmailTemplates,
  getEmailTemplateTypes,
  getEmailTemplatesByTypeId,
  getEmailTemplatesDetails,
  getPreviewTemplate,
} from "src/store/emailTemplates/saga";
import ColumnButton from "src/components/Form/Button/ColumnButton";
import EditEmailTemplatePage from "./edit";
import CreateEmailTemplatePage from "./create";
import CancelAlert from "src/components/CancelAlert";
import { toast } from "react-toastify";
import PreviewEmailTemplate from "./details";
import CustomSelectPicker from "src/components/Form/Input/SelectPicker";

const EmailTemplatesPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const [types, setTypes] = useState<EmailTemplateTypeDto[]>([]);
  const [templates, setTemplates] = useState<EmailTemplateDto[]>([]);
  const [selected, setSelected] = useState<EmailTemplateTypeDto>();
  const [previewContent, setPreviewContent] = useState<string>();
  const [showEdit, setShowEdit] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplateDto>();
  const [showCreate, setShowCreate] = useState(false);
  const [showDelete, setShowDelete] = useState<EmailTemplateDto>();

  useLayoutEffect(() => {
    dispatch(
      getEmailTemplateTypes({
        payload: {
          onSuccess: (m, p, r) => {
            setTypes(p);
            p && p.length > 0 && setSelected(p[0]);
          },
          onError: () => {},
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [dispatch, selected]);
  const getData = () => {
    selected &&
      dispatch(
        getEmailTemplatesByTypeId({
          payload: {
            onSuccess: (m, p, r) => {
              setTemplates(p);
            },
            onError: () => {},
          },
          id: selected.EnumId,
        })
      );
  };
  return (
    <React.Fragment>
      <div
        className="page-content "
        style={{
          fontFamily: "Poppins,sans-serif",
        }}
      >
        <MetaTags>
          <title>E-Posta Tasarım Şablonları | Servis Plan</title>
        </MetaTags>
        <Container fluid>
          {!selected && loading && <Loader />}
          <Row>
            <Col>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-success mb-2"
                  onClick={() => setShowCreate(true)}
                >
                  Yeni Ekle
                </button>
              </div>
              {selected && (
                <CustomSelectPicker
                  data={types}
                  field="typeId"
                  label="Bir Tasarım Şablonu Tipi Seçiniz"
                  labelKey="Name"
                  placeholder="Tasarım Şablonu Seçiniz"
                  value={selected?.EnumId}
                  valueKey="EnumId"
                  cleanable={false}
                  onSelect={(x, item: any) => item && setSelected(item)}
                />
              )}
            </Col>
            <Col md={12}>
              <Table className="table mb-0 table-bordered ">
                <thead className="custom-table-header-colored table-light">
                  <tr>
                    <th>{"Tasarım Şablonu Adı"}</th>
                    <th className="">Aktif</th>
                    <th className="text-end"></th>
                  </tr>
                </thead>
                <tbody>
                  {templates.map(x => (
                    <tr>
                      <td>{x.Name}</td>
                      <td>
                        {x.Active ? (
                          <i className="text-success bx  bx-check-square"></i>
                        ) : (
                          <i className="text-danger mdi mdi-close-box-outline"></i>
                        )}
                      </td>
                      <td className="text-end">
                        <div className="d-flex justify-content-end gap-2">
                          <ColumnButton
                            buttonType="customicon"
                            custombutton={{
                              icon: "mdi mdi-eye",
                            }}
                            onClick={() => {
                              dispatch(
                                getPreviewTemplate({
                                  payload: {
                                    onSuccess: (message, payload) => {
                                      setPreviewContent(payload);
                                      return setShowPreview(true);
                                    },
                                    onError: () => {},
                                  },
                                  id: x.Id,
                                })
                              );
                            }}
                          />
                          <ColumnButton
                            buttonType="editicon"
                            onClick={() => {
                              dispatch(
                                getEmailTemplatesDetails({
                                  payload: {
                                    onSuccess: (message, payload) => {
                                      setSelectedTemplate(payload);
                                      return setShowEdit(true);
                                    },
                                    onError: () => {},
                                  },
                                  id: x.Id,
                                })
                              );
                            }}
                          />
                          <ColumnButton
                            buttonType="deleteicon"
                            onClick={() => {
                              setShowDelete(x);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>{" "}
      {selectedTemplate && (
        <EditEmailTemplatePage
          data={selectedTemplate}
          onHide={() => setShowEdit(false)}
          open={showEdit}
          onSuccessClick={getData}
        />
      )}
      {previewContent && (
        <PreviewEmailTemplate
          onHide={() => setShowPreview(false)}
          open={showPreview}
          content={previewContent}
        />
      )}
      <CreateEmailTemplatePage
        onHide={() => setShowCreate(false)}
        open={showCreate}
        onSuccessClick={getData}
      />
      {showDelete && (
        <CancelAlert
          message={showDelete.Name + " silmek istediğinize emin misiniz?"}
          onConfirm={() => {
            setShowDelete(undefined);
            dispatch(
              deleteEmailTemplates({
                payload: {
                  onSuccess: message => {
                    toast.success(message, {
                      theme: "colored",
                    });
                    getData();
                  },
                  onError: message => {
                    toast.error(message, {
                      theme: "colored",
                    });
                  },
                },
                id: showDelete.Id,
              })
            );
          }}
          onCancel={() => {
            setShowDelete(undefined);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default EmailTemplatesPage;
