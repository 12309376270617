import { MainListPage } from "src/components/main-list-page";
import CreateSupplierIncludedBrandModal from "./create";
import { useParams } from "react-router";
import { deletePrivilegeIncludedBrands } from "src/store/privilege/saga";

const PrivilegeIncludedBrandPage = () => {
  const { id }: { id: string } = useParams();
  const columns = [
    {
      caption: "Kampanya",
      dataField: "PrivilegeTitle",
    },
    {
      caption: "Marka",
      dataField: "VehicleBrandName",
    },
  ];
  return (
    <MainListPage
      url="/api/privilegeincludedbrands/devext"
      columns={columns}
      title={"Markalar"}
      filter={["PrivilegeId", "=", id]}
      deleteButton={{
        deleteAction: deletePrivilegeIncludedBrands,
      }}
      create={{
        loadForm(props) {
          return (
            <CreateSupplierIncludedBrandModal
              onHide={props.onHide}
              open={props.open}
              onSuccessClick={() => {}}
              privilegeId={id}
            />
          );
        },
        type: "modal",
      }}
    />
  );
};
export default PrivilegeIncludedBrandPage;
